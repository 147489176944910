<template>
	<div>
		<f-default-header
			:title="$t('pages.distributors.title')"
			:subtitle="$t('pages.distributors.subtitle')" />

		<f-list />

		<f-actions-btn-holder v-if="permissions.write">
			<v-btn color="primary" elevation="0" large @click="create">
				{{ $t("labels.btnNew") }}
			</v-btn>
		</f-actions-btn-holder>
	</div>
</template>

<script>
import FList from "../../components/distributors/List.vue";

export default {
	components: {
		FList,
	},
	data: {
		permissions: {
			read: false,
			write: false,
		},
	},
	created() {
		this.list();
		this.permissions = this.$getPermissions();
	},
	methods: {
		async list() {
			this.$store.dispatch("system/loading", "data");
			await this.$store.dispatch("distributor/list");
			this.$store.dispatch("system/loading", "done");
		},
		create() {
			this.$router.push({
				name: "distributor-create",
			});
		},
	},
	destroyed() {
		this.$store.dispatch("distributor/clear", "list");
	},
};
</script>
