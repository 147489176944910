<template>
	<f-card>
		<v-data-table
			:headers="headers"
			:items="distributors"
			@click:row="edit"></v-data-table>
		<div v-if="loading">
			<v-progress-linear color="grey" indeterminate height="12" />
		</div>
	</f-card>
</template>

<script>
export default {
	data() {
		return {
			headers: [
				{
					text: this.$t("labels.name"),
					align: "start",
					sortable: true,
					value: "name",
				},
				{
					text: this.$t("labels.createdAt"),
					value: "created_at",
					align: "end",
				},
			],
		};
	},
	computed: {
		distributors() {
			return this.$store.state.distributor.list;
		},
		loading() {
			return !this.$store.state.system.loading.done;
		},
	},
	methods: {
		edit(distributor) {
			this.$router.push({
				name: "distributor-edit",
				params: {
					id: distributor.id,
				},
			});
		},
	},
};
</script>
